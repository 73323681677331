export default {
	name: 'DeleteAssetDialog',
	data() {
		return {
			loadingSave: false,
		};
	},
	props: {
		value: Boolean,
		item: Object,
	},
	computed: {
		dialog: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	methods: {
		close() {
			this.dialog = false;
		},
		async createCalification() {},
	},
};
