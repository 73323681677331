<template>
	<incident-list />
</template>
<script>
import IncidentList from '@/components/incidents/IncidentList/IncidentList.vue';

export default {
	name: 'IncidentListPage',
	components: { IncidentList },
};
</script>
